.container {
    min-height: 100vh;
    background-image: url("../../../public/images/completeProfile/complete_Profile_backImg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
}
.box{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
}
.dashboardcontainer {
    
   
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.80); 
    display: flex;
    justify-content: center;
    text-align: center;
    width: 85vw;
    height:fit-content;
}
.upperImg{
    min-height: 160px;
    background-color: gray;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
    position: relative;
    background-image: url("../../../public/images/completeProfile/upper_img.png");
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
}
.upperImg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.upperForeground{
    position: absolute;
    width: 90%;
    height: 60%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;
    padding: 0 0 15px 0;
}
.createProfileTxt{
    color: #FFF;
    font-family: Righteous;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
.upperForegroundBot{
    margin: 10px 0 0 0;
}
.upperForegroundBotTxt{
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
.sliderCnt{
    width: 100%;
    border-radius: 50px;
    height: 7px;
    background-color: #D9D9D9;
    margin: 7px 0 0 0;
    overflow: hidden;
}
.slider{
    height: 100%;
    width: 25%;
    background-color: #FFCC00;
}