.header {
    font-family: "Righteous", sans-serif;

}
.mainContainer  {
    background: #D6810D !important;
    width: 100%;
    height: 100vh;
   display: flex;
  justify-content: center;

}
.mainContainer img {
    height: 600px;
    width: 370px;
 }
