.header{
    margin-top: 50px;
    font-size: 24px;
    font-family: "Righteous", sans-serif;

}
.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
    background-attachment: fixed;
}

.dashboardcontainer {
    margin-top: 100px;
    background-color: white;
    border-radius: 43px;   
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 95%;
    min-width: 320px;
    max-width: 450px;
}

.btnMain:disabled{
    opacity: 0.5;

}

.upperImg {
    position: absolute;
    top: 45px;
    width: 140px;
    height: 120px;
}

.profileImgCnt{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 1px solid black;
    margin: 20px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}
.profileImgCnt img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.profileImgBtnCnt{
    height: 30%;
    width: 30%;
    border-radius: 50%;
    background-color: whitesmoke;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-5%,-5%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid black;
    overflow: hidden;
    cursor: pointer;
}
.profileImgBtnCnt input, .profileImgBtnCnt label{
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}
.profileImgBtnCnt input{
    z-index: 1;
    opacity: 0;
}
.profileImgBtnCnt label{
    z-index: 2;
}
.profileImgBtnCnt svg{
    height: 60%;
    width: 60%;
}