.header{
  padding-inline: 30px;
  padding-block: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.81);
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  border-bottom: 5px solid #FFCC42;
}
.changeColor:hover{
  color: #FFCC42;
}
.lowerPart{
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
  /* background-color: red; */
}
.contentArea{
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 40px;
}
.contentArea .left{
  display: none;
}
/* .ContentPart{
  position: relative;
  background-color: red;
} */
.barrom{
  display: none;
  height: 100px;
  width: 100px;
  object-fit: contain;
  position: absolute;
  top: 10px;
  left: 100px;
  z-index: 2;
}
.backgroung{
  height: 100%;
  width: 100%;
}
.backgroundImg{
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  /* height: 400px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bottle{
  height: 300px;
  position: absolute;
  bottom:70px;
  left: -10px;
  z-index: 1;
}
.bottle1{
  position: absolute;
  bottom: 0px;
  /* bottom: -330px; */
  left: 0;
  height: 300px;
}
.manImg{
  height: 330px;
  /* width: 200px; */
  z-index: 2;
  position: absolute;
  left: 14px;
  bottom: 0px;
  /* top: 89px; */
}
.middleSection{
  /* padding-inline: 10px; */
  width: 100%;
  height: 100%;
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.middleSection h1,h2{
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin: 0 10px;
  /* font-size: 40px; */
}
.middleSection h2{
  color: #FFCC42;
}
.middleSection p{
  font-size: 10px;
  color: #fff;
  text-align: center;
  margin: 8px 10px 0 10px;
}
.middleSectionImg{
  /* background-color: red; */
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  /* gap: -10px; */
}
.middleSectionImg img{
  /* height: 100px; */
  width: 150px;
}
.rightPart{
  z-index: 0;
  display: none;
  max-width: 300vw;
  max-height: 400px;
  height: 50vh;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50% );
  /* left: 0; */
  /* display: flex;
  justify-content: flex-end; */
  /* background-color: red; */
}
.rightPart img{
  /* position: absolute; */
  /* bottom: -50px; */
  /* right: 50px; */
  /* left: -1px; */
  /* height: 400px; */
  height: 100%;
  width: 100%;
}
@media screen and (min-width:800px) {
  .contentArea .left{
    display: block;
  }
  .rightPart{
    display: block;
  }
  .barrom{
    display: block;
  }
  .middleSection h1 ,h2{
    font-size: 40px;
  }
  .middleSectionImg{
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width:850px) {
  /* .bottle{
    height: 200px;
    position: absolute;
    bottom:40px;
    left: -14px;
    z-index: 1;
  } */
  .bottle1{
    /* position: absolute; */
    /* bottom: 0px; */
    /* bottom: -330px; */
    /* left: 0; */
    /* height: 200px; */
  }
}