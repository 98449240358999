.main{
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
}
.main .left{
  width: 100%;
  height: 300px;
  background: url("../../../public/LandingPageImages/LeftRectangle.png") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 5px solid  #F33;
}
.innerLeft{
  /* background-color: red; */
  padding-inline: 16px;
  max-width: 300px; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}
.innerLeft h5{
  color: #FFCC42;
  font-size: 23px;
  font-weight: 400;
  line-height: 45px;
}
.innerLeft h4{
  color: #ffff;
  font-size: 23px;
  font-weight: 400;
  line-height: 45px;
}
.innerLeft p{
  font-family: "poppins";
  font-size: 11px;
  color: rgba(255, 255, 255, 0.62);
  font-weight: 400;
  line-height: 15px; 
  letter-spacing: 0.34px;
}
.innerLeft button{
  background-color: #FFCC42;
  color: #fff;
  padding: 7px 8px; 
  width: 100px;
  margin: 10px 0 0 0;
}

.right{
  display: grid;
  grid-template-columns: 1fr ;
  background-color: black;
  border-top: 5px solid  #F33;
}
.box1 , .box2, .box3, .box4{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20); 
  border-right: 1px solid rgba(255, 255, 255, 0.20); 
}

.innerbox1, .innerbox2 , .innerbox3 , .innerbox4{
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.box1 img, .box2 img,.box3 img,.box4 img{
  height: 50px;
}
.box1 h6 , .box2 h6, .box3 h6, .box4 h6{
  font-size: 16px;
  color: #FFCC42;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 7px;
  line-height: 20px;
}
.box1 p, .box2 p, .box3 p,.box4 p{
  color: rgba(255, 255, 255, 0.62);
  font-family: "poppins";
  font-size: 12px;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0.000px;
}

@media screen and (min-width:800px) {
  .main{
    grid-template-columns: 1fr 1fr;
  }
  .main .left{
    height: 400px;
  }
  .main .right{
    height: 400px;
    grid-template-columns: 1fr 1fr;
  }
}