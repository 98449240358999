.main{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 50px 0;
}
.part1{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  
} 

.innerPart1{
  width: 100%;
  max-width: 400px;
}

.part1 .leftPart  {
  display: flex;
  justify-content: center;
  align-items: center;
}
.part1 .rightPart{
  width: auto;
  margin: 15px 20px 0 20px;
  z-index: 2;
}

.rightPart h3 {
  color: #000;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0.8px;
  font-size: 24px;
  line-height: 35px;
  margin: 0 0 20px 0;
  /* text-transform: lowercase; */
}
.part1 img{
  height: 400px;
}
.rightPart ul li{
  color: #555;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 25px;
  letter-spacing: 0.4px;
  text-transform: lowercase ;
  list-style: none;
}
.button{
  padding: 13px 26px;
  border-radius: 80px;
  background-color: #FF3333;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "poppins";
  font-weight: 400;
  margin-block: 20px;
}
.lemonImg , .lemonLeft, .lemonImg3{
  display: none;
  /* position: absolute; */
}
.lemonLeft2{
  display: none;
}
.lemonImg1{
  display: none;
  position: absolute;
  right: 0;
  bottom: -10px;
  z-index: 0;
}
.lemonLeft{
  position: absolute;
  left: 0;
}
.lemonImg3{
  position: absolute;
  right: 0;
  bottom: -10px;
  z-index: 0;
}



@media screen and (min-width:800px) {
  .innerPart1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 80%;
    max-width: 900px;
  }
  .part1:nth-child(2) .rightPart{
    order: 1;
  }
  .part1:nth-child(2) .leftPart{
    order: 2;
  }
  .part1 .leftPart{
    flex: 1;
  }
  .part1 .rightPart{
    flex: 1;
    margin: 0 20px 0 20px;
  }
  .lemonImg, .lemonLeft2,.lemonImg1,.lemonImg3{
    display: block;
  }
  .lemonLeft2{
    position: absolute;
    left: 0;
    top: -90px;
    height: 20px;
    z-index: 0;
  }

}