.main{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  padding-inline:100px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.left{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}
.main .right{
  display: none;
  /* background-color: red; */
}
.right{
  display: none;
  position: relative;
  flex: 1;
  display: flex;
  /* background-color: orange; */
}
.left .formBox{
  max-width: 400px;
  padding-top: 20px;
}
.formBox h1{
  padding-bottom: 20px;
  text-align: center;
}
.formBox input , textarea{
  width: 100%;
  outline: none;
  text-indent: 15px;
  background: #FFF;
}
.formBox input{
  height: 45px;
  border-radius: 100px;
  border: 1.534px solid rgba(0, 0, 0, 0.10);
  margin-bottom: 15px;
}
.formBox input::placeholder, textarea::placeholder{
  font-family: "poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.36px;
  color: rgba(85, 85, 85, 0.50);
  text-transform: lowercase;
}
.myTextArea{
  border-radius: 20px;
  border: 1.534px solid rgba(0, 0, 0, 0.10);
}
.btn{
  width: 300px;
  height: 70px;
  border-radius: 100px;
  background: #FF3333;
  color: whitesmoke;
  font-size: 26px;
  box-shadow: -5px -5px 5px lightgray;
}
.Innerright{
  position: relative;
}
.right img{
  height: 400px;
}
.right .iphoneImg{
  height: 350px;
  position: absolute;
  left: -90px;
  top: 30px;
}
.Innerright .glassImg{
  height: 250px;
  position: absolute;
  bottom: -16px;
  right: 200px;
  /* top: 0; */
}
@media screen and (min-width:800px) {
  .left{
    height: 100%;
  }
  .main .right{
    display: block;
  }
}