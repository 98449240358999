.header {
  font-size: 24px;
  font-family: "Righteous", sans-serif;
}
.headers {
  font-size: 16px;
  font-family: "Righteous", sans-serif;
}
.headerss {
  font-size: 20px;
  font-family: "Righteous", sans-serif;
}

.validInputField {
  display: flex;
  border: 2px solid #9d9d9d;
  border-radius: 40px;
  align-items: center;
  width: 40%;
  padding: 0.5rem;
  gap: 16px;
}

.eFrrorInputField {
  display: flex;
  border: 2px solid red;
  border-radius: 40px;
  align-items: center;
  width: 40%;
  padding: 0.5rem;
  gap: 16px;
}

.validTextAreaField {
  display: flex;
  border: 2px solid #9d9d9d;
  border-radius: 40px;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  align-items: center;
  padding: 0.5rem;
  gap: 16px;
  overflow: hidden;
}

.errorTextAreaField {
  display: flex;
  border: 2px solid red;
  border-radius: 40px;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  align-items: center;
  padding: 0.5rem;
  gap: 16px;
}
.validTextInput {
  resize: none;
  outline: none;
  padding: 0;
}

.errorTextInput {
  resize: none;
  outline: none;
  padding: 0;
}
