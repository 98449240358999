.main{
  /* height: 100vh; */
  width: 100vw;
  padding-top: 23px;
  background: url("../../../public/Footer/Rectangle.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* align-items: center; */
}

.innerDiv{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
}
.baroom{
  width: 100px;
  height: 100px;
}
.innerDiv p{
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 23px ;
  font-weight: 400;
  line-height: 35.1px;
  text-transform: lowercase;
  margin-bottom: 10px;
}
.linkItems{
  color: #fff;
  font-family: "poppins";
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 10px 0;
  /* background-color: red; */
}
.linkItems a{
  font-weight: 400;
  font-size: 20px;
  padding: 0 20px;
  text-transform: capitalize;
  line-height: 32px;
}
.istLink{
  border-right: 1px solid #fff;
}

.inputArea {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 40px;
  width: 90%;
  max-width: 450px;
  border-radius: 50px;
  overflow: hidden;
  border: 1.534px solid rgba(0, 0, 0, 0.10);
  padding: 3px;
}
.inputArea input{
  height: 100%;
  border: none;
  outline: none;
  text-indent: 10px;
  padding-right: 10px;
  width: 100%;
}
.inputArea input::placeholder{
  color: rgba(85, 85, 85, 0.50);
  font-family: "Poppins";
  font-weight: 400;
  font-size: 11px;
}
.inputArea button{
  background-color: #FF3333;
  color: #fff;
  border: none;
  font-family: "poppins";
  height: 100%;
  border-radius: 80px;
  padding: 0 30px;
}
.logoPart{
  /* background-color: red; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 20px;
  margin: 20px 0 20px 0;
}
.logoPart img{
  height: 30px;
  background-color:rgba(255, 255, 255, 0.20);
  padding: 8px;
  border-radius: 50px;
}
.innerDiv hr{
  border: 1px solid rgba(255, 255, 255, 0.31);
  height: 0.1px;
  width: 100%;
}
.innerDiv h6{
  padding: 20px 0;
  font-family: "poppins";
  color: #fff;
  font-weight: 400;
}