@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.custom-glass-form {
  padding: 20px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
  border: 1px solid rgba(255, 255, 255, 0.4); /* Light border */
  border-radius: 15px; /* Rounded corners */
  backdrop-filter: blur(10px); /* Glass blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  color: #333;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  margin: auto;
  position: relative;
  /* min-width: 800px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
