.my-scroll-hider{
  scrollbar-width: none;
}

.my-scroll-hider::-webkit-scrollbar {
  width: 0; 
  height: 0; /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
  display: none;

}

.my-scroll-hider::-webkit-scrollbar-thumb {
  background: #D93853;
}

.MuiPickersLayout-contentWrapper,
.MuiDateCalendar-root,
.MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer,
.MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer > div,
.MuiDayCalendar-root,
.MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition
{
  width: 100% !important;
  height: 100% !important;
  max-height: unset !important;
  min-height: unset;
}

.MuiDayCalendar-weekContainer,.MuiDayCalendar-header{
  width: 100%;
  height: auto;
}
.MuiDayCalendar-header>span{
  width: auto !important;
  flex: 1;
  aspect-ratio: 1;
}
.MuiPickersDay-root,
.MuiButtonBase-root-MuiPickersDay-root,
.MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.MuiTypography-root-MuiDayCalendar-weekDayLabel
{
  width: auto !important;
  flex: 1;
  aspect-ratio: 1;
}

.MuiYearCalendar-root{
  width: 100% !important;
}

.MuiDayCalendar-monthContainer {
  height: 400px;
  display: flex;
  flex-direction: column;
}
.MuiDayCalendar-weekContainer{
  flex: 1;
}
.MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition{
  min-height: unset !important;
  overflow: hidden !important;
  height: 500px !important;
}