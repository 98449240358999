.header {
  font-size: 24px;
  font-family: "Righteous", sans-serif;
}
.headers {
  font-size: 16px;
  font-family: "Righteous", sans-serif;
}
.headerss {
  font-size: 20px;
  font-family: "Righteous", sans-serif;
}
