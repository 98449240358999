.header {
  font-family: "Righteous", sans-serif;
}

/* success msg */

.mainContainer {
  /* background: #D6810D !important; */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.mainContainer img {
  height: 600px;
  width: 370px;
}

.number {
  font-family: "Righteous", sans-serif;
  color: red;
}

.sliderBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  font-size: 14px;
}

.sliderBtn img {
  display: none;
  animation: fadeIn 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.sliderBtnActive img {
  display: block;
}

.sliderBtnActive {
  transition: background-color 0.3s cubic-bezier(0.8, -0.01, 0.28, 0.81),
    color 0.3s cubic-bezier(0.8, -0.01, 0.28, 0.81);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3333;
  color: white;
  height: 100%;
  width: auto;
  flex: 1;
  border-radius: 100px;
  padding: 10px 25px;
  font-size: 14px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
