.main{
  display: grid;
  gap: 10px;
}
.box1{
  min-height: 200px;
  background-color: #FFCC42;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.box2{
  min-height: 200px;
  background: url("../../../public/PhotoGrid/box2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  background-color: gray;
}
.box3{
  min-height: 200px;
  /* grid-column-end: span 2; */
  background: url("../../../public/PhotoGrid/box3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: orange  ;
}
.box4{
  min-height: 200px;
  background-color: pink;
  background: url("../../../public/PhotoGrid/box4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover  ;
 
}
.box5{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-color: purple;
  background: url("../../../public/PhotoGrid/box5.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.box5 p{
  color: #fff;
  font-family: "poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: 0.48px;
}
.box5 h1{
  color: #fff;
  font-size: 70px;
  font-family: "poppins";
  font-weight: 600;
  letter-spacing: 1px;
}
.box6{
  min-height: 200px;
  background-color: #2D2D2D; display: flex;
  justify-content: center;
  align-items: center;
}
.innerBox6{
  height: 100%;
  padding-inline: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerBox6 .myul li{
  color: #fff;
  font-size: 13px;
  font-family: "poppins";
  list-style: none;
  margin-bottom: 10px;
}
.myul li:before {
  content: '✓';
  margin-right: 9px;
}
.box7{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-color: chocolate;
  background: url("../../../public/PhotoGrid/Box7.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.innerBox7{
  margin: 0;
}
.innerBox7 h1{
  font-size: 70px;
  font-family: "poppins";
  font-weight: 600;
  letter-spacing: 1.92px;
  margin-top: -15px;
  margin-bottom: -20px;
}
.innerBox7 p{
  font-family: "poppins";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.48px;
}
.innerBox7 p, .innerBox7 h1{
  color: #fff;
}
.stars{
  display: flex;
}
@media screen and (min-width:800px) {
  .main{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .box2{
    grid-column-end: span 2;
  grid-row-start: span 2;
  }
  .box3{
    grid-column-end: span 2;
  }
  .box4{
    grid-row-start: span 2;
  }
}