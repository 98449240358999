.blogs-container {
  padding: 0;
  max-width: 100%;
  margin: 0;
}

.blogs-header {
  text-align: center;
  width: 100%;
  background: #fbfbfa;
}

.blogs-header p {
  font-size: 1rem;
  color: #666;
  padding-left: 20px;
  padding-right: 20px;
}

.blogs-list {
  margin-left: 10%;
  margin-right: 10%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 10%;
}

.blog-item {
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-img {
  border-radius: 25px 25px 0 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.blog-title {
  font-family: "poppins";
  font-weight: 600;
  margin-top: 10px;
  font-size: 1.2rem;
  padding-left: 4px;
  text-align: center;
  color: #333;
}

.loading-container {
  display: flex;
  font-family: "poppins";
  font-weight: 200;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  height: 20vh;
}

@media (max-width: 1024px) {
  .blogs-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogs-list {
    grid-template-columns: 1fr;
  }

  .blogs-header p {
    font-size: 0.9rem;
  }

  .blog-title {
    font-size: 1rem;
  }
}
