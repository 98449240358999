.header {
    /* font-size: 24px; */
    font-family: "Righteous", sans-serif;

}
.headers{
    font-size: 16px;
    font-family: "Righteous", sans-serif;

}
.headerss{
    font-size: 20px;
    font-family: "Righteous", sans-serif;

}
.availCnt{
    border-radius: 10.994px;
    background: #FFF;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
}
.availCntDate{
    height: 52px;
    width: 52px;
    border: 2px solid rgb(255, 51, 51);
    border-radius: 10px;
    background-color: rgba(255, 51, 51,.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.availCntDate p{
    color: rgb(255, 51, 51);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}
.allDatesCnt{
    flex: 1;
    height: auto;
    min-height: 52px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.timeTxt{
    color: black;
    margin: 0 20px;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
}
.unavailText{
    color: lightgray;
    margin: 0 20px;
}

.editIconCnt{
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 


